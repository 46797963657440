.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  h1 {
    margin-bottom: 2rem;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 300px;

    .form-group {
      margin-bottom: 1rem;

      label {
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      input {
        padding: 0.5rem;
        font-size: 1rem;
      }
    }

    button {
      padding: 0.5rem 1rem;
      font-size: 1rem;
      background-color: #007bff;
      color: #fff;
      border: none;
      cursor: pointer;
    }
  }
}
