.car-management {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  h1,
  h2 {
    color: #333;
    margin-bottom: 20px;
  }

  h1 {
    font-size: 24px;
    font-weight: bold;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
  }
}

.car-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.car-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  h3 {
    margin-top: 0;
    font-size: 18px;
    font-weight: 600;
  }

  p {
    margin: 5px 0;
  }

  &.in-use {
    background-color: #ffeeee;
    border: 1px solid #ffcccc;
  }
}

.report-form {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;

  .tab-buttons {
    display: flex;
    margin-bottom: 20px;

    button {
      padding: 10px 15px;
      border: none;
      background-color: #f0f0f0;
      cursor: pointer;
      flex: 1;

      &.active {
        background-color: #007bff;
        color: #fff;
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .form-group {
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 5px;
    }

    input,
    select {
      width: 100%;
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }

    .odo-input {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .submit-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }

  .radio-group {
    display: flex;
    gap: 1rem;
  }

  .radio-label {
    display: flex;
    align-items: center;
    cursor: pointer;

    input[type='radio'] {
      margin-right: 0.5rem;
    }
  }

  .odo-input {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.active-reports {
  list-style-type: none;
  padding: 0;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  li {
    padding: 15px;
    border-bottom: 1px solid #eee;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: #f8f9fa;
    }
  }
}
